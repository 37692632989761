<!-- 购物车商品列表
local carlist--存的是本地购物车数据
session carlist--存的是暂时选取需要支付的商品
-->
<template>
  <!-- v-if='initcar' -->
  <div class="buycarlist">
    <div v-if='noneCar' v-show='!carlist||carlist&&carlist.length<1' class='noneCar'>
      <p class='noneCarsvg'><svg class="icon"><use xlink:href="#iconziyuan11"></use></svg></p>
      <p class="nonemenu"><button v-if='!this.$store.state.user' @click='noneCarlg' class="noneCarlg">登录</button><button @click='shopingLink' class='noneCargw'>去购物>></button></p>
      <p class="nonetxt">购物车内暂时没有商品，点击去购物来添加您喜欢的商品吧~</p>
    </div>
    <el-row class='tr' v-show='carlist&&carlist.length>0'>
      <el-col class='th' :span="4"><span class="check" :class='{active:choiceAll}' @click='choicecheckAll'><svg class="icon"><use xlink:href="#iconwancheng"></use></svg></span><button @click='choicecheckAll'>全选</button></el-col>
      <el-col class='th' :span="4">商品信息</el-col>
      <el-col class='th' :span="4">单价（元）</el-col>
      <el-col class='th' :span="4">数量</el-col>
      <el-col class='th' :span="4">小计</el-col>
      <el-col class='th' :span="4">操作</el-col>
    </el-row>
    <el-row class='tr' v-for='i,n in carlist' :key='n' v-show='carlist&&carlist.length>0' :class='{activer:i._choice}'>
      <el-col class='td' :span="4"><span class="check" :class='{active:i._choice}' @click='choicecheck(i,n)'><svg class="icon"><use xlink:href="#iconwancheng"></use></svg></span></el-col>
      <el-col class='td' :span="4">
        <div class="img">
          <img :src='i.url' @click='lingdetail(i)'>
        </div>
        <div class="info">
          <h6 @click='lingdetail(i)'>{{i.name}}</h6>
          <p>数量：{{i.DzproductnumberName}}</p>
        </div>
        <div class="info1">
          <p>规格：{{i.DzspecificationName}}</p>
          <p>材质：{{i.DzmaterialName}}</p>
        </div>
      </el-col>
      <el-col class='td' :span="4">
        <p class="price"><span>&yen;</span>{{i.price |formatPrice}}</p>
      </el-col>
      <el-col class='td' :span="4" @click.native='countsC(i)' >
          <div class="count" >
            <el-input-number v-model="i.number1"  size="small" controls-position="right" :min='1' ref='inputs' @blur='countsC(i)'  @change='counts'></el-input-number><span></span>
          </div>
      </el-col>
      <el-col class='td' :span="4">
        <p class="price1"><span>&yen;</span>{{i.price*i.number1 |formatPrice}}</p>
      </el-col>
      <el-col class='td' :span="4">
        <button title='删除商品'  class="del" @click='delitem(i,n)'><svg class="icon"><use xlink:href="#iconshanchu1"></use></svg></button>
      </el-col>
    </el-row>
    <el-row class='tr' v-show='carlist&&carlist.length>0'>
      <el-col class='td' :span="6"><span class="check" :class='{active:choiceAll}' @click='choicecheckAll'><svg class="icon"><use xlink:href="#iconwancheng"></use></svg></span><button @click='choicecheckAll'>全选</button></el-col>
      <el-col class='td' :span="6"><button title='删除全部商品'  class="del1" @click='delBatch'>删除</button></el-col>
      <el-col class='td' :span="6"><p class="choiceNumber">已选择<span>{{preCount}}</span>件商品</p></el-col>
      <el-col class='td' :span="6"><p class="choiceNumber1">总金额（不含运费）：<span><i>&yen;</i>{{allMoney | formatPrice}}</span></p></el-col>
      <el-col class='td' :span="6"><button class="gobuy" @click='linksettle'>去结算</button></el-col>
    </el-row>
  </div>
</template>
<script>
import {mixin,setCarlist} from '../../assets/js/common.js' //公用js
export default {
  name: 'buycarlist',
  mixins: [mixin],//混入
  data(){
    return {
      carlist:[],
      // carlist:[
      //     // {url:require("../../assets/img/buycarlist/img.jpg"),name:'广告纸巾定做钱夹式纸巾荷包纸巾宣传餐厅纸巾印刷logo微微定原...',number:500,tip:'2层10片',tip1:'175*190mm',price:418,number1:1,id:1,_choice:false},
      //     // {url:require("../../assets/img/buycarlist/img.jpg"),name:'广告纸巾定做钱夹式纸巾荷包纸巾宣传餐厅纸巾印刷logo微微定原...',number:500,tip:'2层10片',tip1:'175*190mm',price:418,number1:2,id:2,_choice:false},
      //     // {url:require("../../assets/img/buycarlist/img.jpg"),name:'广告纸巾定做钱夹式纸巾荷包纸巾宣传餐厅纸巾印刷logo微微定原...',number:500,tip:'2层10片',tip1:'175*190mm',price:418,number1:1,id:3,_choice:false},
      // ],//列表数据
      carlistCapy:[],
      choiceAll:false,//全选索引
      preCount:0,//选择商品数量
      allMoney:0,//结算总价格
      initcar:false,// 没有物品
      noneCar:false,// 显示隐藏
      noneCar1:false,// 显示隐藏
      // carlistChoices:[],
    }
  },
  props: {
    msg: String
  },
  methods:{
    lingdetail(item){
      // console.log(item)
      // return;
      var _idcx = item.DynamicId?item.DynamicId:''
      // 跳转详情
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id::res.data.Data.producttypeid,idcx:'',detailType:1}})
        var id = item.JobSubTypesID + '.html'
        this._open({path:'/predetail',query:{idcx:_idcx,id:id,}})
      }})
    },
    noneCarlg(){
      // 登录
      this.$store.dispatch("cmtlogintagger",!this.$store.state.logintagger)
    },
    shopingLink(){
      // 去购物
      // 跳转购物车
      this._router({exp:false,catch:()=>{
        // this.$router.push({path:'accountlogin',query:{redirect:'buycar'}})
        var pms = '/buycar.html'
        this._open({path: "/accountlogin",query: {redirect:pms}})
      },then:()=>{
        this.$router.push('/index.html')
      }})
    },
    choicecheckAll(){
      // 全选
      this.choiceAll = !this.choiceAll
      if(this.choiceAll){
        for(let i in this.carlist){
          this.carlist[i]._choice = true
        }
      }else{
        for(let i in this.carlist){
          this.carlist[i]._choice = false
        }
      }
      this.setPreCount();
      window.sessionStorage.setItem('carlist',JSON.stringify(this.carlist))
    },
    choicecheck(item,index){
      // 单选
      this.carlist[index]._choice = !this.carlist[index]._choice
      this.setPreCount();
      if(!item._choice){
        this.offchoices();
      }else{
        this.setchoices();
      }
      window.sessionStorage.setItem('carlist',JSON.stringify(this.carlist))
    },
    offchoices(){
      //取消全选
      for(let i in this.carlist){
        if(!this.carlist[i]._choice){
          return this.choiceAll = false
        }
      }
    },
    setchoices(){
      //选中全选
      var all = true;
      for(let i in this.carlist){
        if(!this.carlist[i]._choice){
          all = false
        }
      }
      // console.log(all)
      if(all){
        this.choiceAll = true
      }
    },
    setPreCount(){
      // 设置已选择商品数---设置已选商品数总价格
      var count = 0;
      var price = 0;
      for(let i in this.carlist){
        if(this.carlist[i]._choice){
          count = count + this.carlist[i].number1
          price = price + this.carlist[i].price*this.carlist[i].number1
        }
      }
      this.preCount = count
      this.allMoney = price
    },
    counts(value){
      // console.log(value)
      // 数量计算
      this.carlistCapy = this.carlist
      if(!this.$store.state.user){
        setCarlist({method:'del'})
        for(let i in this.carlist){
          var _obj = {};
          _obj.buynumber = this.carlist[i].number1
          _obj.carId = this.carlist[i].carId
          setCarlist({method:'set',data:_obj})
        }
      }
      this.setPreCount()
    },
    countsC(i){
      // 登录改变商品数量
      if(this.$store.state.user){
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'Shop/AddSubtractShoppingCart?productId='+i.ProductType+'&num='+i.number1,this.keys({token:this._token({method:'get'})}))
          .then(res => {
              if(res.data.Success){
                // if(res.data.Data.LoinState&&res.data.Data.LoinState=='fail'){
                //   this.$store.dispatch("cmtSetUser",null);//赋值
                //   this._usermet({method:'del'});//存本地
                //   this._token({method:'del'});//存本地
                //   return  this.$router.push({path:'accountlogin',query:{redirect:this.$route.fullPath}})
                // }
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        }})
      }
    },
    delitem(item,index){
      // 从购物车中删除-单
      this.$MessageBox.confirm('确定要删除该商品吗？','删除商品',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass:'dellist'
      }).then(() => {
        if(this.$store.state.user){
          this._axios({then:()=>{
            this.axios.post(process.env.VUE_APP_URL+'Shop/deleteShoppingCart?jobId='+item.id,this.keys({token:this._token({method:'get'})}))
            .then(res => {
                if(res.data.Success){
                  // if(res.data.Data.LoinState&&res.data.Data.LoinState=='fail'){
                  //   this.$store.dispatch("cmtSetUser",null);//赋值
                  //   this._usermet({method:'del'});//存本地
                  //   this._token({method:'del'});//存本地
                  //   return  this.$router.push({path:'accountlogin',query:{redirect:this.$route.fullPath}})
                  // }
                  // this.$MessageBox.alert('删除成功',{type:'success'});
                  this.getUserCarlist({CustomerID:this.$store.state.user.CustomerID})
                  this.setPreCount()
                }else{
                  this.$MessageBox.alert(res.data.Error,{type:'error'});
                }
              }, res => {
                // this.$MessageBox.alert('系统异常',{type:'error'});
              })
          }})
        }else{
          // 本地删除
          // this.$MessageBox.alert('删除成功',{type:'success'});
          this.carlist.splice(index,1)
          setCarlist({method:'del'})
          for(let i in this.carlist){
            var _obj = {};
            _obj.buynumber = this.carlist[i].number1
            _obj.carId = this.carlist[i].carId
            setCarlist({method:'set',data:_obj})
          }
          var _carlist = setCarlist({method:'get'})
          if(_carlist){
            this.$store.dispatch('cmtBuycarum',parseInt(_carlist.length))
          }
          // else{
          //   this.$store.dispatch('cmtBuycarum',0)
          // }
          this.setPreCount()
          // end
        }
      }).catch(() => {
      });
    },
    delBatch(){
      var _ok= false;
      for(let i in this.carlist){
        if(this.carlist[i]._choice){
          _ok = true
        }
      }
      if(!_ok){
        return this.$MessageBox.alert('请选择要删除的商品',{type:'warning'});
      }
      // 从购物车中删除-批量
      // 从购物车中删除-单
      this.$MessageBox.confirm('确定要删除该商品吗？','删除商品',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass:'dellist'
      }).then(() => {
        // 根据索引批量删除，数组删除的过程中索引会变化，
        if(this.$store.state.user){
          var delarr= [];
          for(let i in this.carlist){
              if(this.carlist[i]._choice){
                delarr.push(this.carlist[i].id)
              }
          }
          var _delarr = delarr.length>1?delarr.join(','):delarr[0]
          this._axios({then:()=>{
            this.axios.post(process.env.VUE_APP_URL+'Shop/deleteShoppingCart?jobId='+_delarr,this.keys({token:this._token({method:'get'})}))
            .then(res => {
                if(res.data.Success){
                  // if(res.data.Data.LoinState&&res.data.Data.LoinState=='fail'){
                  //   this.$store.dispatch("cmtSetUser",null);//赋值
                  //   this._usermet({method:'del'});//存本地
                  //   this._token({method:'del'});//存本地
                  //   return  this.$router.push({path:'accountlogin',query:{redirect:this.$route.fullPath}})
                  // }
                  // this.$MessageBox.alert('删除成功',{type:'success'});
                  this.getUserCarlist({CustomerID:this.$store.state.user.CustomerID})
                  this.setPreCount()
                }else{
                  this.$MessageBox.alert(res.data.Error,{type:'error'});
                }
              }, res => {
                // this.$MessageBox.alert('系统异常',{type:'error'});
              })
          }})
        }else{
          // 本地删除
          // this.$MessageBox.alert('删除成功',{type:'success'});
          setCarlist({method:'del'})
          var delarr= [];
          for(let i in this.carlist){
              if(this.carlist[i]._choice){
                delarr.push(this.carlist[i].carId)
              }
          }
          for(let i in delarr){
            for(let j in this.carlist){
              if(delarr[i]==this.carlist[j].carId){
                this.carlist.splice(j,1)
              }
            }
          }
          for(let i in this.carlist){
            var _obj = {};
            _obj.buynumber = this.carlist[i].number1
            _obj.carId = this.carlist[i].carId
            setCarlist({method:'set',data:_obj})
          }
          var _carlist = setCarlist({method:'get'})
          if(_carlist){
            this.$store.dispatch('cmtBuycarum',parseInt(_carlist.length))
          }
          // else{
          //   this.$store.dispatch('cmtBuycarum',0)
          // }
          this.setPreCount()
          // end
        }
      }).catch(() => {

      });
    },
    addUserCarlist(params){
      // 给用户批量添加数据
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'Shop/BatchAddShoppingCart?productIds='+params._ids+'&nums='+params.nums+'&SellingTypes='+params.types+'&dynamicIds='+params.idcxs,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              // if(res.data.Data.LoinState&&res.data.Data.LoinState=='fail'){
              //   this.$store.dispatch("cmtSetUser",null);//赋值
              //   this._usermet({method:'del'});//存本地
              //   this._token({method:'del'});//存本地
              //   return  this.$router.push({path:'accountlogin',query:{redirect:this.$route.fullPath}})
              // }
              if(params.fn){
                params.fn()
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
        }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
        })
      }})
    },
    getUserCarlist(params){
      // 获取用户购物车列表
      this._axios({then:()=>{
      // this.axios.post(process.env.VUE_APP_URL+'Shop/ShoppingCartList?CustomerId='+params.CustomerID,this.keys())
      this.axios.post(process.env.VUE_APP_URL+'Shop/ShoppingCartList',this.keys({token:this._token({method:'get'})}))
        .then(res => {
          if(res.data.Success){
           // if(res.data.Data.LoinState&&res.data.Data.LoinState=='fail'){
           //    this.$store.dispatch("cmtSetUser",null);//赋值
           //    this._usermet({method:'del'});//存本地
           //    this._token({method:'del'});//存本地
           //    return  this.$router.push({path:'accountlogin',query:{redirect:this.$route.fullPath}})
           //  }
            this.$store.dispatch('cmtBuycarum',parseInt(res.data.Data.list.length))
            var _data = [];
            for(let i in res.data.Data.list){
              var _str = res.data.Data.list[i].JObName.indexOf('[');
              var _end = res.data.Data.list[i].JObName.indexOf(']');
              var _s = res.data.Data.list[i].JObName.substring(_str+1,_end)
              var _obj = {};
              _obj.url = this._src + res.data.Data.list[i].ImagePath
              _obj.name =res.data.Data.list[i].JObName.substring(0,_str)
              _obj.number = _s.split('-')[2]
              _obj.tip = _s.split('-')[1]
              _obj.tip1 = _s.split('-')[0]
              _obj.price = res.data.Data.list[i].Price
              _obj.DzspecificationName   = res.data.Data.list[i].DzspecificationName
                _obj.DzmaterialName = res.data.Data.list[i].DzmaterialName
                _obj.DzproductnumberName = res.data.Data.list[i].DzproductnumberName
              _obj.number1 = res.data.Data.list[i].Copies
              _obj.id = res.data.Data.list[i].jobID
              _obj.ProductType = res.data.Data.list[i].ProductType
              _obj.SellingType = res.data.Data.list[i].SellingType
              _obj.DynamicId = res.data.Data.list[i].DynamicId
              _obj.JobSubTypesID = res.data.Data.list[i].JobSubTypesID
              _obj._choice = false
              _data.push(_obj)
            }
            // this.initcar = true

            this.$set(this,'carlist',_data)
            this.$store.dispatch('cmtBuycarum',this.carlist.length)
            this.noneCar = true
            var carlistchoice = JSON.parse(window.sessionStorage.getItem('carlist'))
            // console.log(carlistchoice)
            if(carlistchoice){
              this.choiceAll = false
              for(let i in carlistchoice){
                if(carlistchoice[i]._choice){
                  for(let j in this.carlist){
                    if(this.carlist[j].ProductType==carlistchoice[i].ProductType){
                      this.carlist[j]._choice = true
                    }
                    if(this.carlist[j].ProductType==carlistchoice[i].ProductType){
                      this.carlist[j]._choice = true
                    }
                  }
                }
              }
              this.setchoices()
            }else{
              this.choiceAll = true
              for(let i in this.carlist){
                this.carlist[i]._choice = true
              }
            }
            this.setPreCount();
          }else{
            this.$MessageBox.alert(res.data.Error,{type:'error'});
          }
        }, res => {
          // this.$MessageBox.alert('系统异常',{type:'error'});
        })
      }})
    },
    getLocalCarlist(params){
      // 获取本地的购物车数据
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'Shop/ShoppingCartCacheList?productIds='+params._ids+'&nums='+params.nums+'&dynamicIds='+params.idcx,this.keys())
        .then(res => {
            if(res.data.Success){
              var _data = [];
              for(let i in res.data.Data){
                var _str = res.data.Data[i].JObName.indexOf('[');
                var _end = res.data.Data[i].JObName.indexOf(']');
                var _s = res.data.Data[i].JObName.substring(_str+1,_end)
                var _obj = {};
                _obj.url = this._src + res.data.Data[i].ImagePath
                _obj.name =res.data.Data[i].JObName.substring(0,_str)
                _obj.number = _s.split('-')[2]
                _obj.tip = _s.split('-')[1]
                _obj.tip1 = _s.split('-')[0]
                _obj.price = res.data.Data[i].Price
                _obj.DzspecificationName   = res.data.Data[i].DzspecificationName
                _obj.DzmaterialName = res.data.Data[i].DzmaterialName
                _obj.DzproductnumberName = res.data.Data[i].DzproductnumberName
                _obj.number1 = res.data.Data[i].Copies
                _obj.carId = res.data.Data[i].ProductType
                _obj.ProductType = res.data.Data[i].ProductType
                _obj.SellingType = res.data.Data[i].SellingType
                _obj.DynamicId = res.data.Data[i].DynamicId
                _obj.JobSubTypesID = res.data.Data[i].JobSubTypesID
                _obj.id = res.data.Data[i].jobID
                _obj._choice = false
                _data.push(_obj)
              }
              var _carlist = setCarlist({method:'get'})
              // console.log(_carlist)
              if(_carlist){
                for(let i in _data){
                  for(let j in _carlist){
                    if(_carlist[j].carId===_data[i].ProductType){
                      _data[i].SellingType = _carlist[j].type
                    }
                  }
                }
              }
              // this.initcar = true
              this.$set(this,'carlist',_data)
              this.$store.dispatch('cmtBuycarum',this.carlist.length)
              this.noneCar = true
              var carlistchoice = JSON.parse(window.sessionStorage.getItem('carlist'))
              if(carlistchoice){
                this.choiceAll = false
                for(let i in carlistchoice){
                  if(carlistchoice[i]._choice){
                    for(let j in this.carlist){
                      if(this.carlist[j].ProductType==carlistchoice[i].ProductType){
                        this.carlist[j]._choice = true
                      }
                      if(this.carlist[j].ProductType==carlistchoice[i].ProductType){
                        this.carlist[j]._choice = true
                      }
                    }
                  }
                }
                this.setchoices()
              }else{
                this.choiceAll = true
                for(let i in this.carlist){
                  this.carlist[i]._choice = true
                }
                window.sessionStorage.setItem('carlist',JSON.stringify(this.carlist))
              }
              this.setPreCount();
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
    },
    linksettle(){
      // 购买跳转结算产品
      var _ok= false;
      for(let i in this.carlist){
        if(this.carlist[i]._choice){
          _ok = true
        }
      }
      if(!_ok){
        return this.$MessageBox.alert('请选择要购买的商品',{type:'warning'});
      }
      this._axios({exp:true,then:()=>{
        // 验证登录
        var _ids = [],nums = [];
        for(let i in this.carlist){
          if(this.carlist[i]._choice){
            _ids.push(this.carlist[i].id)
            nums.push(this.carlist[i].number1)
          }
        }
        this.axios.post(process.env.VUE_APP_URL+'Shop/SaveCart?JobIDs='+(_ids.length>1?_ids.join(','):_ids[0])+'&copies='+(nums.length>1?nums.join(','):nums[0]),this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              // if(res.data.Data.LoinState&&res.data.Data.LoinState=='fail'){
              //   this.$store.dispatch("cmtSetUser",null);//赋值
              //   this._usermet({method:'del'});//存本地
              //   this._token({method:'del'});//存本地
              //   return  this.$router.push({path:'accountlogin',query:{redirect:this.$route.fullPath}})
              // }
              this.$router.push({path:'/settlebill',query:{_ids:_ids.join(','),nums:nums.join(',')+'.html'}})
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      },catch:()=>{
        // 用户不存在
        // 记录用户不存在选择的商品
        // this.$router.push({path:'accountlogin',query:{redirect:'buycar'}})
        window.sessionStorage.setItem('carlist',JSON.stringify(this.carlist))
        this.$store.dispatch("cmtlogintagger",!this.$store.state.logintagger)
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // console.log(1)
      // 获取购物车列表
      if(this.$store.state.user){
        var _carlist = setCarlist({method:'get'})
        if(_carlist){
          var _ids = '',nums='',types = '',idcxs='';
          for(let i in _carlist){
            if(i<(_carlist.length-1)){
              _ids+=_carlist[i].carId+','
              nums+=_carlist[i].buynumber+','
              types+=_carlist[i].type+','
              idcxs+=_carlist[i].idcx+','
            }
            if(i==(_carlist.length-1)){
              _ids+=_carlist[i].carId
              nums+=_carlist[i].buynumber
              types+=_carlist[i].type
              idcxs+=_carlist[i].idcx
            }
          }
          this.addUserCarlist({CustomerID:this.$store.state.user.CustomerID,_ids:_ids,nums:nums,types:types,idcxs:idcxs,fn:()=>{
            setCarlist({method:'del'})
            this.getUserCarlist({CustomerID:this.$store.state.user.CustomerID})
          }})
        }else{
          this.getUserCarlist({CustomerID:this.$store.state.user.CustomerID})
        }
      }else{
        var _carlist = setCarlist({method:'get'})

        if(_carlist){
          var _ids = '',nums='',types='',idx='';
          for(let i in _carlist){
            if(i<(_carlist.length-1)){
              _ids+=_carlist[i].carId+','
              nums+=_carlist[i].buynumber+','
              types+=_carlist[i].type+','
              idx+=_carlist[i].idcx+','
            }
            if(i==(_carlist.length-1)){
              _ids+=_carlist[i].carId
              nums+=_carlist[i].buynumber
              types+=_carlist[i].type
              idx+=_carlist[i].idcx
            }
          }
          // console.log(_ids,nums)
          this.getLocalCarlist({_ids:_ids,nums:nums,idcx:idx})
        }else{
          this.noneCar = true
        }
      }
      // end

    })
  },
  watch:{
    '$store.state.user'(n,o){
      if(this.$route.path.indexOf('/buycar')!=-1){
        if(n){
          // 登录后自动购买跳转
          var _carlist = setCarlist({method:'get'})
          if(_carlist){
            var _ids = '',nums='',types = '',idcxs='';
            for(let i in _carlist){
              if(i<(_carlist.length-1)){
                _ids+=_carlist[i].carId+','
                nums+=_carlist[i].buynumber+','
                types+=_carlist[i].type+','
                idcxs+=_carlist[i].idcx+','
              }
              if(i==(_carlist.length-1)){
                _ids+=_carlist[i].carId
                nums+=_carlist[i].buynumber
                types+=_carlist[i].type
                idcxs+=_carlist[i].idcx
              }
            }
            this.addUserCarlist({CustomerID:n.CustomerID,_ids:_ids,nums:nums,types:types,idcxs:idcxs,fn:()=>{
              setCarlist({method:'del'})
              this.getUserCarlist({CustomerID:n.CustomerID})
            }})
          }else{
            this.getUserCarlist({CustomerID:n.CustomerID})
          }
        }else{
          var _carlist = setCarlist({method:'get'})
          if(_carlist){
            var _ids = '',nums='',types='',idcxs='';
            for(let i in _carlist){
              if(i<(_carlist.length-1)){
                _ids+=_carlist[i].carId+','
                nums+=_carlist[i].buynumber+','
                types+=_carlist[i].type+','
                idcxs+=_carlist[i].idcx+','
              }
              if(i==(_carlist.length-1)){
                _ids+=_carlist[i].carId
                nums+=_carlist[i].buynumber
                idcxs+=_carlist[i].idcx
              }
            }
            // console.log(_ids,nums)
            this.getLocalCarlist({_ids:_ids,nums:nums,idcx:idcxs})
          }
        }
      }
    },
  },
  destroyed(){
    // 组件销毁清空定时变量
    // var carlistchoice = JSON.parse(window.sessionStorage.getItem('carlist'))
    // window.sessionStorage.removeItem('carlist')
  }
}
</script>
<style lang="scss">
.dellist {
  .el-message-box__btns {
    button {
      &:hover {
        color:#333;background: rgba(0,0,0,0.08);border:1px solid #ccc;
      }
    }
    .el-button--primary {
      background: #f54d70;border:1px solid #f54d70;
      &:hover {
        color:#fff;background: #f54d70;opacity: 0.8;border:1px solid #f54d70;
      }
    }
  }
}
</style>
<style  lang="scss" scoped>
.buycarlist {
  .noneCar {
    text-align: center;
    .noneCarsvg {
      svg {
        width: 150px;height: 150px;
      }
    }
    .nonemenu {
      margin-top:-15px;
      .noneCarlg {
        background: #ed008c;color:#fff;width: 45px;height: 28px;line-height: 28px;border-radius: 3px;margin-right: 15px;font-size: 14px;
        &:hover {
          opacity: 0.8;
        }
      }
      .noneCargw {
        background: none;font-size: 14px;color:#0d61c3;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .nonetxt {
      font-size: 14px;color:#797979;margin-top:10px;
    }
  }
  width: 1200px;margin:0 auto;
  .tr {
    border-bottom:1px solid #dadada;
    .th {
      display: flex;align-items: center;justify-content: center;height: 60px;
      .check {
        width:14px;height: 14px;border:1px solid #bebebe;border-radius: 3px;cursor: pointer;margin-right: 4px;
        svg {
          width: 12px;height: 12px;vertical-align: 2px;padding-left:1px;fill: #f83252;visibility: hidden;
        }
        &:hover {
          opacity: 0.8;
        }
      }
      .active {
        border:1px solid #f83252;
        svg {
          visibility: visible;
        }
      }
      button {
        background: none;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .td {
      display: flex;align-items: center;justify-content: center;height:150px;
       button {
        background: none;
        &:hover {
          opacity: 0.8;
        }
      }
      .check {
        width:14px;height: 14px;border:1px solid #bebebe;border-radius: 3px;cursor: pointer;
        svg {
          width: 12px;height: 12px;vertical-align: 2px;padding-left:1px;fill: #f83252;visibility: hidden;
        }
        &:hover {
          opacity: 0.8;
        }
      }
      .active {
        border:1px solid #f83252;
        svg {
          visibility: visible;
        }
      }
      .img {
        width: 140px;height: 140px;display: flex;justify-content: center;align-items: center;
        img {
          max-width: 120px;max-height: 120px;cursor: pointer;
        }
        &:after {
          content: '';width: 140px;height: 10px;visibility: hidden;display: block;
        }
      }
      .info {
        float:left;width: 220px;
        h6 {
          font-size: 14px;line-height: 20px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;line-clamp: 2;-webkit-line-clamp: 2; -webkit-box-orient: vertical;color:#393939;cursor: pointer;
        }
        p {
          // padding:3px 10px;
          display: inline-block;background: none;font-size: 14px;color:#7d7e82;border-radius: 5px;margin-top:10px;
        }
      }
      .info1 {
        float:left;margin-left:10px;color:#a1a1a1;font-size: 14px;
      }
      .price {
        color:#393939;
        span {
          margin-right: 3px;
        }
      }
      .price1 {
        color:#393939;font-weight: bold;
        span {
          margin-right: 3px;
        }
      }
      .count {
        /deep/ .el-input-number--small {
          width: 80px;
          .el-input__inner {
            padding-left: 0px;padding-right: 32px;
          }
        }
      }
      .del {
        background: none;width: 25px;height: 25px;
        svg {
          width: 25px;height: 25px;fill: #545454;
        }
      }
      .del1 {
        background: none;color:#3b6dc2;
      }
      .choiceNumber {
        color:#8d8d8d;font-size: 14px;
        span {
          color:#e73545;margin:0 2px;
        }
      }
      .choiceNumber1 {
        color:#8d8d8d;font-size: 14px;
        span {
          color:#e73545;font-size: 24px;font-weight: bold;vertical-align: -4px;
          i {
            font-style: normal;font-size: 16px;margin-right: 4px;
          }
        }
      }
      .gobuy {
        width: 130px;height: 50px;background: #f44f55;border-radius: 5px;font-size: 18px;color:#fff;font-weight: bold;letter-spacing: 2px;text-indent: 2px;
      }
      &:nth-child(2) {
        justify-content: flex-start;
      }
    }
    .el-col-4 {
      &:nth-child(1) {
        width: 80px;
      }
      &:nth-child(2) {
        width: 520px;
      }
      &:nth-child(n+3){
        width: calc((100% - 600px)/4);
      }
    }
    .el-col-6 {
      &:nth-child(1) {
        width: 80px;
      }
      &:nth-child(2) {
        width: 520px;
      }
      &:last-child {
        width: 130px;
      }
      &:nth-child(3) {
        width: calc((100% - 730px)/3);
      }
      &:nth-child(4) {
        width: calc(((100% - 730px)/3)*2);
      }
    }

    &:last-child {
      border-bottom:0px;
      .td {
        height:90px;
        .check {
          margin-right: 4px;
        }
      }
    }
  }
  .activer {
    background: #fef3f5;
  }
}
</style>
