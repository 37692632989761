<!-- 购物车---登录组件 -->
<template>
  <div class="buylogin">
    <svg class="icon"><use xlink:href="#iconkefu"></use></svg>
    <h6>您还没有登录，登录后购物车的商品将保存到您的账号中</h6>
    <button @click='login' >立即登录</button>
  </div>
</template>
<script>
export default {
  name: 'buylogin',
  props: {
    msg: String
  },
  methods:{
    login(){
      this.$store.dispatch("cmtlogintagger",!this.$store.state.logintagger)
    }
  }
}
</script>
<style  lang="scss" scoped>
.buylogin {
  width: 1198px;height: 48px;border:1px solid #d6ccc3;line-height: 48px;
  h6 {
    display: inline-block;font-size: 14px;color:#fa642f;
  }
  svg {
    fill: #fa642f;vertical-align: -5px;width: 22px;height: 22px;margin:0 10px;
  }
  button {
    padding:5px 12px;background: #f85276;font-size: 14px;color:#fff;border-radius: 3px;margin-left:15px;
  }
}
</style>
