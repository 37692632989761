<!-- 购物车页面  -->
<template>
  <div class="buycar">
	<el-row class='head'>
	  <el-col :span="12">
	  	<logotitle title='购物车'></logotitle>
	  </el-col>
	  <el-col :span="12">
	  	<hotsearch class='sc'></hotsearch>
	  </el-col>
	</el-row>
	<el-row class='head'>
	  <el-col :span="24">
	  	<buylogin class='lg' v-if='!this.$store.state.user&&this.$store.state.buycarum>0'></buylogin>
	  </el-col>
	</el-row>
	<buycarlist class='list'></buycarlist>
	<div class="coms">
		<likes1 class='likes1'></likes1>
		<otherBrand class='otherBrand'></otherBrand>
	</div>
  </div>
</template>
<script>
import logotitle from '@/components/logotitle/logotitle.vue';//图片标题组件
import hotsearch from '@/components/hotsearch/hotsearch.vue';//带热门搜索组件
import buylogin from '@/components/buylogin/buylogin.vue';//登录组件
import likes1 from '@/components/likes1/likes1.vue';//猜你喜欢 第二种
import otherBrand from '@/components/otherBrand/otherBrand.vue';//其他商家
import buycarlist from '@/components/buycarlist/buycarlist.vue';//购物车列表
export default {
  name: 'buycar',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
  	return {
  		htmltitle:'购物车-微微定'
  	}
  },
  components: {
  	logotitle,
  	hotsearch,
  	buylogin,
  	likes1,
  	otherBrand,
  	buycarlist
  },
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  	this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.buycar {
  	width: 100%;background: #fff;
	.head {
		width: 1200px;margin:0 auto;padding-top:20px;
		.sc {
			margin-top:14px;
		}
		.lg {
			margin:10px 0px 20px 0px;
		}
	}
	.list {
		margin-bottom:20px;
	}
	.coms {
		width: 1200px;margin:0 auto;
		.likes1 {
			padding-bottom:50px;
		}
		.otherBrand {
			padding-bottom:20px;
		}
	}
}
</style>
